
.bank-page-title {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 400;
}

.bank-page-sub-title {
    text-align: center;
    font-size: 14px;
}

/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {    
    .challenge-container {
        box-shadow: 0 6px 21px var(--oc-gray-3);
        border-radius: 4px;
        width: 300px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        height: 85vh;
    }    
}
/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {    
    .ch_width{
        --challenge-width:600px
    }
    .challenge-container {
        box-shadow: 0 6px 21px var(--oc-gray-3);
        border-radius: 4px;
        width: var(--challenge-width, 100%);        
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
        height: 70vh;
    }    
}