.payment-item > .container:hover{
    background-color: rgb(245, 245, 245);
    cursor: pointer;
}
.payment-item > .container{
    display: grid;
    grid-template-rows:  auto auto auto ;
    border: 1px solid #ededed;
    border-radius: 0;
    padding: 10px;
}

.payment-item > .container > .header > .type{
    display: grid;
    grid-template-columns:  auto auto auto;
    gap: 10px;
    place-items: center;
}
.header{
    display: grid;
    grid-template-columns:  auto auto ;
    gap: 10px;
    justify-content: space-between;
}
.actions{
    /* margin-top: 20px; */
}
.cc-image{
    height: 20px;
}
.cc_row{
    place-self: end;
}
.cc-icon{
    font-size: 26px;
}
.cc-type-icon{
    color: #0061B3;
    font-size: 25px;
    padding-right: 3px;
}
.payment-type{
    height: 30px;
}
.title{
    font-size: 14px;
    font-weight: bold;
}
