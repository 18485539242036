.event-container{
    position: relative;
    padding: 10px;
    display: grid;
    width: fit-content;
    margin: 10px auto;
    background-color: white;
    border-radius: 7px;
    box-shadow: 1px 1px 5px 1px var(--oc-gray-3);
    text-align: center;
}

.flows-title {
    margin: 10px auto;
    font-size: 20px;
}

.checkIcon {
    color: var(--checkout-btn);
    margin-right: 10px;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
}

.step {
    font-size: 16px;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.animation2{
    animation-name            : totalFadeIn;
    animation-duration        : 1s;
    animation-direction       : normal;
    animation-fill-mode: both;
}

.animation{
    animation-name            : fadeIn;
    animation-duration        : 1s;
    animation-direction       : normal;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    0% {opacity: 0.2;}
    100% {opacity: 1;}
}

@keyframes totalFadeIn {
    0% {opacity: 0; font-size: 0; }
    0.1% {font-size: 18px; }
    100% {opacity: 1; }
}

.loader {
    opacity: 0;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid var(--checkout-btn);
    width: 18px;
    height: 18px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    animation-duration        : 1s;
    animation-iteration-count: 2;
    justify-self: center;
    margin: auto 0;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

