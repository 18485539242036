.submit-form {
    margin-top: 30px;
}

.form-field-title {
    font-size: 12px;
    color: var(--oc-gray-7);
    font-weight: 400;
}
.checkout-btn{
    color: white;
    font-weight: bold;
    background-color: var(--checkout-btn);
}
.input-error{
    border-color: rgba(255, 0, 0, 0.336);
}
.error-text{
    color: rgb(201, 30, 0);
    cursor: pointer;
}
.form-input{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
