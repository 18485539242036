.fade-in{
    animation-name            : fadeIn;
    animation-duration        : 3s;    
    animation-direction       : normal;
    animation-fill-mode: forwards;
}
.fade-out{
    animation-name            : fadeOut;
    animation-duration        : 2s;    
    animation-direction       : normal;
    animation-fill-mode: forwards;
}
@keyframes fadeIn {
    0%   { opacity:0; visibility: hidden; }    
    100% { opacity:1; visibility: visible; }
}
@keyframes fadeOut {
    0%   { opacity:1;  visibility: visible;}    
    100% { opacity:0; visibility: hidden;}
}