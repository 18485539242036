.demo-flow {
  padding: 20px;
  background-color: var(--oc-gray-9);
  border-bottom: 1px solid var(--oc-gray-2);
  color: var(--oc-white);
}

.iframe-wrap {
  padding: 7px;
}

.three-ds-toggle {
  align-self: center;
}

.email {
  grid-area: email;
}

.first-name {
  grid-area: first-name;
}

.last-name {
  grid-area: last-name;
}

.address {
  grid-area: address;
}

.zip-code {
  grid-area: zip-code;
}

.checkout-button {
  grid-area: checkout-button;
}

.expiry {
  grid-area: expiry;
}

.cvv {
  grid-area: cvv;
}

.expiry_cvv {
  grid-area: expiry_cvv;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
}

.secure-lock {
  display: none;
  font-weight: 300;
  grid-area: secure-lock;
  text-align: right;
}
.secure-lock > span{
  margin-left:5px;
}
.lock-icon{
  color: hsl(158, 100%, 33%);;
}
.shipping-info-title {
  grid-area: shipping-info-title;
  column-gap: 5px;
  place-content: start;
  display: grid;
  grid-template-columns: auto auto;
  font-weight: bold;
  color: #4f6073;
}

.payment-info-title {
  margin-bottom: 5px;
  grid-area: payment-info-title;
  display: grid;
  place-items: end;
  grid-template-columns: auto auto;
  justify-content: space-between;
  font-weight: bold;
  color: #4f6073;
}

.payment-cc {
  display: grid;
  grid-template-columns: auto max-content;
  justify-content: left;
  grid-column-gap: 10px;
}

.challengeSelector {
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 5px;
}

.cart {
  grid-area: cart;
}

.checkout {
  grid-area: checkout;
  padding: 10px;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .checkout {
  }

  .brand {
    font-size: 20px;
    font-weight: 500;
  }

  .input-container {
    display: grid;
    grid-row: auto auto auto;
    row-gap: 10px;
  }

  .secure-lock {
    font-size: 14px;
    text-align: center;
  }

  .cc_image {
    height: 20px;
  }

  .cart {
    padding: 10px;
  }

  .error-message {
    margin-top: 10px;
    width: auto;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 481px) {
  .checkout {
    background-color: white;
    box-shadow: 1px 1px 5px 1px var(--oc-gray-2);
  }

  .brand {
    font-size: 30px;
    font-weight: 500;
  }

  .input-container {
    display: grid;
    grid-column-gap: 10px;
    grid-template-rows: auto auto;
  }

  #iframecontainer {
    transition: height 0.3s ease;
    display: none;
  }

  .shipping-info {
    display: grid;
    grid-column-gap: 10px;
    grid-template-areas:
      'shipping-info-title secure-lock'
      'email email'
      'first-name last-name'
      'address zip-code'
      'countries currency';
    padding: 10px;
  }

  .payment-info {
    padding: 10px;
  }

  .cc_image {
    height: 40px;
  }

  .cart {
    margin-left: 20px;
    /* margin: 50px 30px 0px 30px;  */
  }

  .error-message {
    margin-top: 10px;
    width: auto;
    background-color: var(--oc-red-8);
    color: var(--oc-white);
  }
}

.select.is-grey:not(:hover)::after {
  border-color: var(--oc-gray-6);
}

.three-ds-title {
  color: var(--oc-white-2);
}

.challengeSelector > label:hover {
  color: var(--oc-gray-5);
}

.correlation-id-toggle:hover {
  color: var(--oc-gray-5);
}
.payment-types {
  display: grid;
  row-gap: 20px;
  grid-template-rows: auto auto auto;
  place-content: baseline;
}
.countries {
  grid-area: countries;
  margin-top: 5px;
}
.currency {
  grid-area: currency;
  margin-top: 5px;
}
.payment-radio {
  place-self: center;
}
.savedPaymentImg {
  margin-left: 2px;
  height: 18px;
}
.saved-payment-container {
  display: grid;
  column-gap: 10px;
  place-content: baseline;
  grid-template-columns: auto auto;
}
.new-payment-button {
  display: grid;
  column-gap: 10px;
  grid-template-columns: auto auto;
  background-color: var(--new-payment-btn-color);
  color: white;
}
.saved-payment-cvv {
  width: 90px;
}
.full-width {
  width: 100%;
}
.payment-type {
  display: grid;
  font-size: 14px;
  grid-template-columns: 1fr 4fr;
  column-gap: 20px;
}
.pay_with_label {
  place-self: center;
}
.payment-logos {
  place-self: end;
}

.my-payments {
  font-size: 12px;
}

.edit-icon {
  place-self: center;
  font-size: 14px;
}
.cc-detailes{
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 10px;
  margin-top: 10px;
}
.dropdown-item{
  width: fit-content;
}
.dropdown{
  width: 100%;
}
.dropdown-trigger{
  width:100%;
}
.button{
  width: 100%;
  height: 30px;
  font-size: 12px;
  border-color: #10a19a47;
  text-align: left;
}
.button:hover{
  border-color: #10a19a47;
}
.has-text-left {
  justify-content: flex-start;
}
.dropdown-item{
  width: 100%;
  height: 30px;
  font-size: 12px;
  cursor: pointer;
}
.drop-down{
  justify-content: space-between;
}
.dropdown-item:hover{
  background-color: var(--oc-gray-2);
}
.dropdown-item.active{
  background-color: var(--oc-gray-2);
}
.dropdown-content{
  padding: 0;
}
.dropdown-image{
  height: 20px;
  margin-right: 10px;
}
.dropdown-icon{
  color: hsl(158, 100%, 33%);
}
.dropdown-menu{
  width: 50%;
}
