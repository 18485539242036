body,
html,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
  background: var(--oc-gray-0);
}

.rcw-widget-container .loader {
  display: none;
}
.rcw-widget-container > button,
.rcw-conversation-container > .rcw-header {
  background: var(--active-log-message-color);
}

.rcw-message > .rcw-response {
  background-color: white;
  color: var(--oc-gray-0);
}
.rcw-widget-container {
  width: 0;
  right: 706px;
}

.rcw-message-text p {
    margin: 0;
    color: var(--brand-color);
}

#console {
  background-color: var(--oc-gray-9);
  overflow-y: scroll;
}

.columns {
  height: 100%;
  margin: 0;
}

.bank-page-title {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
}

.bank-page-sub-title {
  text-align: center;
  font-size: 14px;
}

/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {
  .data-entry-container {
    display: grid;
    grid-template-areas: 'cart' 'checkout';
    height: 100vh;
  }

  .title {
    font-size: 18px;
    color: var(--oc-gray-7);
  }

  .page-layout {
    display: grid;
    grid-template-columns: 100vw;
  }

  #log-container {
    height: max-content;
  }
}

/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {
  body,
  html,
  #root {
    overflow-y: hidden;
  }

  .data-entry-container {
    display: grid;
    grid-template-areas: 'checkout cart';
    grid-template-columns: 48% 48%;
    justify-content: center;
    margin-top: 10px;
  }

  .title {
    font-size: 20px;
    color: var(--oc-gray-7);
    font-weight: 400;
  }

  .page-layout {
    display: grid;
    grid-template-columns: auto minmax(0, 50%);
    height: 100vh;
  }
}

.hints-btn {
  position: fixed;
  left: 0;
  top: 10px;
  margin-left: 10px;
  font-weight: 500;
  z-index: 99;
}

.hints {
  background-color: var(--oc-gray-9) !important;
  z-index: 100 !important;
}

.hints-container {
  border-top: 1px solid var(--oc-gray-7);
  background-color: var(--oc-gray-9);
  padding-left: 5px;
}

.hide-hints {
  display: none;
}

.hint-list {
  margin-top: 10px;
}

.hint-list > li {
  margin-bottom: 5px;
  color: var(--oc-gray-6);
}

.hint-list > li > span {
  font-weight: bold;
}

.quick-view-title {
  color: var(--oc-grey-6);
  font-weight: 500;
}

.challenge-modal {
  background-color: var(--oc-white);
  overflow-y: hidden;
}
.quick-flow-title{
  color: white;
  font-size: 30px;
  font-weight: 300;
}
.quick-flow-picker-modal {
  overflow: hidden;
  text-align: center;
}

.center-container {
  display: grid;
  place-content: center;
}
.custom-card {
  margin: 20px;
}
.modal-container {
  padding: 20px;
}
