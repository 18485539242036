@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono");

.drower-container {
  background: #242424;
}

.hints {
  overflow-y: scroll;
}

.log-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.log-container.compress {
  grid-template-rows: 0px 40px 1fr;
}

.flow-container {
  padding: 10px;
  border-bottom: 1px solid #2e2d2d;
}

.console-container > .log-message {
  word-break: break-word;
  margin-bottom: 5px;
  white-space: pre-wrap;
}
.console-container > .log-message:hover {
  cursor: pointer;
  background-color: var(--active-log-message-color);;
}

.toolbar {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  height: fit-content;
  background-color: #333333;
}

.toolbar-title {
  color: grey;
}

.toolbar-buttons {
  display: grid;
  align-items: center;
  grid-template-columns: auto;
  grid-gap: 10px;
  margin-right: 15px;
}

.console-btn {
  color: grey;
}

.console-btn :hover {
  color: lightgray;
  cursor: pointer;
}

.issuer-connection-box {
  position: absolute;
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .console-container {
    position: relative;
    font-family: "IBM Plex Mono", monospace;
    font-weight: 600;
    font-size: 12px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 10px;
    margin-top: 10px;
  }
  .console-container:focus { outline: none; }

  .scroll-notif{
    position: absolute;
    z-index: 100;
    top: 5px;
    right: 5px;
    font-size: 30px;
    color: #ffffff59;
    cursor: pointer;
  }

  .quickview.is-active {
    width: 50% !important;
  }

  .flow-selector {
    display: grid;
    grid-template-columns: repeat(auto-fill,250px);
    grid-gap: 4px;
  }

  .log-header {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    font-size: 16px;
    padding-top: 5px;
    background-color: #333333;
    color: var(--oc-white);
  }

  .log-buttons {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-content: end;
    grid-gap: 10px;
  }

  .log-title {
    margin-left: 10px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  .console-container {
    font-family: "IBM Plex Mono", monospace;
    height: max-content;
    font-weight: 600;
    font-size: 12px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 10px;
    margin-top: 10px;
  }

  .flow-selector {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 5px;
  }

  .log-header {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    font-size: 16px;
    padding-top: 5px;
    background-color: #333333;
    color: var(--oc-white);
  }

  .log-buttons {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: auto auto auto auto;
  }

  .log-title {
    display: none;
  }
}

.browser-console {
  color: #00cec5;
  /* color: var(--oc-orange-4); */
}

.merchant-console {
  color: #cb8bff;
  /* color: var(--oc-grape-4); */
}

.error-console {
  color: var(--oc-red-5);
}

.default-console {
  color: var(--oc-white);
}

.payload-console {
  color: #00d885;
  /* color: var(--oc-green-5); */
}

.forter-console {
  color: #e6c208;
}
.background-action-console{
  color: #a8befc88;
}
.user-action-console{
  color: #f78644;
}
.console-quick-view {
  border-left: 1px solid var(--oc-gray-7);
  background-color: #21262a;
  width: 100%;
}

.custom-tabs {
  margin: 0 !important;
  font-size: .85rem;
}

.tab-text {
  margin-left: 5px;
}

.tabs.is-toggle a {
  border-width: 0;
}

.tabs.is-toggle li a {
  color: white;
}

.tabs.is-toggle li a:hover {
  color: white;
  background-color: var(--oc-gray-9);
}

.tabs.is-toggle li:last-child a {
  border-radius: 0;
}

.tabs.is-toggle li:first-child a {
  border-radius: 0;
}

.tabs.is-toggle li.is-active a {
  background-color: var(--oc-gray-9);
}

.tabs.is-toggle li.is-active a:hover {
  color: white;
}

.three-ds-settings-section {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 20px;
  color: #b1b1b1;
  border-bottom: 1px solid #2e2d2d;
  font-size: .85rem;
}

.hints-section {
  padding: 20px;
  color: #b1b1b1;
  border-bottom: 1px solid #2e2d2d;
  font-size: .85rem;
}

.hints-section > span {
  font-weight: bold;
}

.custom-radio {
  font-size: .85rem;
  margin-left: 0 !important;
}

.options-menu {
  display: grid;
  grid-template-rows: auto auto auto;
}

.options-button {
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-item {
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  padding-right: 10px !important;
  text-align: right !important;
}

.flow-selector-title {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.flow-button {
  color: black;
  font-size: 12px !important;
  font-weight: bold;
  border: 0;
}

.flow-button:hover {
  color: white;
}

.flow-button.is-active {
  background-color: var(--oc-teal-9);
  border-color: var(--oc-teal-9);
  color: var(--oc-white);
}

.approve-flow {
    background-color: var(--oc-teal-7);
    color: black;
    border: var(--oc-teal-7) solid 1px;
}

.decline-flow {
  background-color: #f65252;
  border: #f65252 solid 1px;
  color: black;
}

.verification-req-flow {
  background-color: #f9b005;
  border: #f9b005 solid 1px;
  color: black;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #242424;
}

::-webkit-scrollbar-thumb {
  background: var(--oc-gray-3);
  border-radius: 10px;
}

.products-select {
  width: fit-content;
}

.flow-util-button-icon {
  margin-right: 10px;
}

.util-button {
  background-color: #565552;
  border-color: transparent;
  color: whitesmoke;
}

.util-button:focus {
  background-color: #2f2f2f;
  border-color: transparent;
  color: whitesmoke;
}

.util-button:hover {
  background-color: #2f2f2f;
  border-color: transparent;
  color: whitesmoke;
}
.sectionPicker{
  margin-left:10px;
}

.active-log-item{
  background-color: var(--active-log-message-color);
}
.flow-types{
  overflow: auto;
  max-height: 50px;
}

.hide {
  display: none;
}
