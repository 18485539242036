/* Extra Small Devices, Phones */

.logo{
    width: 70px;
}
@media only screen and (max-width: 480px) {
        .powered-by {
        display: none;
    }
}
@media only screen and (min-width: 992px) {    
    .powered-by {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: start;
        font-weight: 600;
        font-size: 12px;
        color: #7b8180;
        grid-gap: 5px;
        margin-right: 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
    }
}