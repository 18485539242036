.notification-custom {
    position: absolute;
    background-color: #ffb101;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
    z-index: 101;
}

.notification-custom-description {
    color: #1b3344;
    margin-bottom: 10px;
}

.acs-loader {
    text-align: center;
}

.line-scale div {
    background-color: #1b3344 !important;
}