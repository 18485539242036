.top-bar {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  box-shadow: 0px -3px 8px 0px;
  max-height: 50px;  
}
.user-container {
  display: grid;
  place-items: center;
  grid-template-columns: auto auto;
  column-gap: 5px;
  margin: 10px;
}
.avatar {
  font-size: 25px;
  color: var(--avatar-color);
}
.username {
  font-size: 15px;
}
.back-button {
}

.back-button :hover {
  color: lightgray;
  cursor: pointer;
}
.brand-container {
  display: grid;
  margin-left: 10px;
  column-gap: 10px;
  place-items: center;
  grid-template-columns: auto auto auto;
}
.brand-name {
  font-size: 20px;
}
.brand-logo {
  font-size: 20px;
  color: var(--brand-color);
}
